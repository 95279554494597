<template>
  <div class="page">
    <div class="cloud">
      <img src="@/assets/images/cloud.png" />
    </div>
    <div class="main-content">
      <div class="review-wrap">
        <div>
          <div class="card-wrap">
            <div class="card">
              <div class="content">{{content.content}}</div>
              <div class="author">———{{content.leave_name}}</div>
              <div class="datetime">{{content.created_at}}</div>
              <div class="btns">
                <div
                  class="like-btn"
                  :class="{'actived':content.isParise==1}"
                  @click="praise"
                >点赞（{{content.praise_num}}）</div>
                <!-- <div class="review-btn">评论</div> -->
              </div>
              <div class="comments" v-if="content.comment_list&&!!content.comment_list.length">
                <div
                  class="comment-item"
                  v-for="(item,index) in content.comment_list"
                  :key="index"
                >{{item.username}}：&nbsp;&nbsp;{{item.comment}}</div>
              </div>
            </div>
          </div>
          <div class="review-input-wrap">
            <textarea v-model="reviewContent" placeholder="写下你想说的话"></textarea>
          </div>
          <div class="submit-btn" @click="submit">
            <div>发表评论</div>
          </div>
        </div>
      </div>

      <div class="footer">
        <div class="other-href" @click="$router.go(-1)">返回</div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api";
export default {
  data() {
    return {
      content: {
        id: null,
        content: "",
        leave_name: "",
        created_at: "",
        praise_num: 0,
      },
      reviewContent: "",
    };
  },
  created() {
    this.getDetails(this.$route.params.id);
  },
  methods: {
    async getDetails(id) {
      let res = await api.getCommentDetails(id);
      this.content = res;
    },
    toNextPage(url) {
      this.$router.push(url);
    },
    async submit() {
      if (!this.reviewContent) {
        this.$toast("请写下你想说的话");
        return false;
      }
      let res = await api.comment({
        comment: this.reviewContent,
        message_wall_id: this.$route.params.id,
      });
      this.reviewContent = "";
      await this.getDetails(this.$route.params.id);
      this.$bus.$emit("reloadMessageById", this.content);
    },
    async praise() {
      await api.praise(this.$route.params.id);
      await this.getDetails(this.$route.params.id);
      this.$bus.$emit("reloadMessageById", this.content);
      this.$toast.success("操作成功！");
    },
  },
  components: {},
};
</script>

<style lang="scss" scoped>
.page {
  height: 100%;
  position: relative;
  background-color: #127987;
  .cloud {
    width: 100%;
    height: 246px;
    position: absolute;
    top: 50%;
    z-index: 1;
    margin-top: -123px;
    animation: 14s linear 0s infinite normal both running
      tempKeyframesForMotion0;
    img {
      height: 100%;
    }
  }

  .main-content {
    position: absolute;
    top: 20px;
    right: 0;
    bottom: 20px;
    left: 0;
    z-index: 10;
    display: flex;
    flex-flow: column;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    .review-wrap {
      flex: 1;
      position: relative;
      & > div {
        position: absolute;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
      }
    }
    .footer {
      text-align: center;
      .other-href {
        font-size: 14px;
        color: rgb(255, 255, 255);
        text-decoration-line: underline;
        text-align: center;
        display: inline-block;
        margin: 0 auto;
        padding: 8px 16px;
        & + .other-href {
          margin-top: 10px;
        }
      }
    }
  }
}
.card-wrap {
  margin: 0 20px;
  .card {
    border: 1px solid #fff;
    box-sizing: border-box;
    padding: 5px;
    .content {
      font-size: 12px;
      line-height: 20px;
      color: rgb(252, 243, 203);
      word-wrap: break-word;
      word-break: normal;
    }
    .author {
      text-align: right;
      font-size: 12px;
      line-height: 20px;
      color: rgb(252, 243, 203);
      margin-top: 12px;
    }
    .datetime {
      text-align: right;
      font-family: Tahoma, Helvetica, Arial;
      color: rgb(255, 255, 255);
      font-size: 12px;
    }
    .btns {
      .like-btn {
        padding: 0 5px;
        height: 22px;
        border: 1px solid #fff;
        line-height: 22px;
        font-size: 14px;
        display: inline-block;
        color: #fff;
        &.actived {
          color: #127987;
          background-color: #fff;
        }
      }
      .review-btn {
        padding: 0 5px;
        height: 22px;
        border: 1px solid #fff;
        line-height: 22px;
        font-size: 14px;
        display: inline-block;
        color: #fff;
        margin-left: 5px;
      }
    }
    .comments {
      padding: 5px 0;
      .comment-item {
        color: rgb(255, 255, 255);
        font-size: 12px;
        margin: 5px 0;
        word-wrap: break-word;
        word-break: normal;
      }
    }
  }
}
.review-input-wrap {
  border: 1px solid #fff;
  margin: 10px 20px;
  padding: 10px;
  height: 80px;
  flex: 1;
  box-sizing: border-box;
  textarea {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    background-color: transparent;
    border-style: none;
    color: #fff;
    &::placeholder {
      color: #91bdc5;
    }
  }
}
.submit-btn {
  text-align: center;
  & > div {
    margin: 10px auto;
    display: inline-block;
    padding: 0 12px;
    border: 1px solid #fff;
    line-height: 22px;
    font-size: 14px;
    color: #fff;
  }
}
</style>